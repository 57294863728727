<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length > 0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="user">
                            <option value="all" :selected="user == 'all'">Todos</option>
                            <option v-for="(item, index) in users" :value="item.id" :key="index">{{item.nome}}: {{item.endereco}}</option>
                        </select>
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                    </div>
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row ml-0 mt-3 mb-3 mr-0" v-show="managers.length > 0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                            <option value="" selected>Todos</option>
                            <option v-for="(item, index) in managers" :value="item.usuario" :key="index">{{item.nome}}: {{item.endereco}}</option>
                        </select>
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" type="text" placeholder="Pesquisar gerente..." v-model="searchs">
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <div class="btn-group w-100" :class="{'mt-3': platform == 'mobile'}">
                            <button type="button" class="btn btn-block btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span><i class="fas fa-file-chart-line mr-2"></i>Relatório</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item" type="button" @click="selectDialog(1)"><i class="fas fa-thumbs-up mr-2"></i>Positivo</button>
                                <button class="dropdown-item" type="button" @click="selectDialog(2)"><i class="fas fa-thumbs-down mr-2"></i>Negativo</button>
                            </div>
                        </div>
                    </div>
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mt-3': platform == 'mobile'}" v-model="include_commission">
                            <option value="1" selected>Com comissão do gerente</option>
                            <option value="2" selected>Sem comissão do gerente</option>
                        </select>
                    </div>
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="users.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Não encontramos nenhum gerente!</p>
                    </div><!-- /alert-danger -->
                    <div class="card mb-3" v-for="(item, index) in filteredListManagers" :key="index" :class="`capture-${item.id}`">
                        <div class="card-header">
                            <div class="row">
                                <div class="col text-truncate mt-1" :class="{'col-8': platform == 'mobile'}">{{item.nome}}: {{item.endereco}}</div>
                                <div class="col-auto" :class="{'col-4': platform == 'mobile'}">
                                    <div class="btn-group w-100">
                                        <button type="button" class="btn btn-sm btn-block btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span v-if="platform == 'desktop'">Mais</span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <h6 class="dropdown-header">Relatório</h6>
                                            <div class="dropdown-divider"></div>
                                            <button class="dropdown-item" type="button" @click="printReport(item)"><i class="fas fa-print mr-2"></i>Imprimir</button>
                                            <button class="dropdown-item" type="button" @click="savePDF(item)"><i class="far fa-file-pdf mr-2"></i>Salvar PDF</button>
                                            <button class="dropdown-item" type="button" @click="saveImage(item)"><i class="fas fa-file-image mr-2"></i>Salvar imagem</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row pb-2">
                                <div class="col text-truncate">Apurou</div>
                                <div class="col text-truncate">{{item.total_apuracao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Comissão<span class="float-right text-danger">{{item.comissao}}%</span></div>
                                <div class="col text-truncate">{{calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, determinedCommission(item.id)) | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{item.num_apostas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Ganhadores</div>
                                <div class="col text-truncate">{{item.num_premios | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Tipo de comissão</div>
                                <div class="col text-truncate">{{determinedCommission(item.id) == 'sim' ? 'Apurado' : 'Saldo do caixa'}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{item.total_premios | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'Comissões'}}</div>
                                <div class="col text-truncate">{{item.total_comissao + item.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (ao-vivo)' : 'Comissões (AV)'}}</div>
                                <div class="col text-truncate">{{item.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em lançamentos' : 'S. Lançamentos'}}</div>
                                <div class="col text-truncate">{{item.lancamentos | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em retiradas' : 'S. Retiradas'}}</div>
                                <div class="col text-truncate">{{item.retiradas | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Saldo do caixa</div>
                                <div class="col text-truncate" :class="positiveOrNegative(item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, determinedCommission(item.id)))">{{item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, determinedCommission(item.id)) | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Saldo líquido</div>
                                <div class="col text-truncate" :class="positiveOrNegative(item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, determinedCommission(item.id)) + item.lancamentos - item.retiradas)">{{item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, determinedCommission(item.id)) + item.lancamentos - item.retiradas | formatCoin}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                    <div class="mb-3" v-if="managers.length">
                        <div class="card mt-3">
                            <div class="card-header">Relatório completo</div>
                            <div class="card-body">
                                <div class="row pb-2">
                                    <div class="col text-truncate">Apurou</div>
                                    <div class="col text-truncate">{{relatorio.total_apuracao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Qtd. Apostas</div>
                                    <div class="col text-truncate">{{relatorio.num_apostas | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">Qtd. Ganhadores</div>
                                    <div class="col text-truncate">{{relatorio.num_premios | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Saldo em prêmios</div>
                                    <div class="col text-truncate">{{relatorio.total_premios | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'Comissões'}}</div>
                                    <div class="col text-truncate">{{relatorio.total_comissao + relatorio.total_comissao_gerentes | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (VEN)' : 'Comissões (VEN)'}}</div>
                                    <div class="col text-truncate">{{relatorio.total_comissao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (GER)' : 'Comissões (GER)'}}</div>
                                    <div class="col text-truncate">{{relatorio.total_comissao_gerentes | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em lançamentos' : 'S. Lançamentos'}}</div>
                                    <div class="col text-truncate">{{relatorio.lancamentos | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em retiradas' : 'S. Retiradas'}}</div>
                                    <div class="col text-truncate">{{relatorio.retiradas | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Saldo do caixa</div>
                                    <div class="col text-truncate" :class="positiveOrNegative(relatorio.total_saldo)">{{relatorio.total_saldo | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2">
                                    <div class="col text-truncate">Saldo líquido</div>
                                    <div class="col text-truncate" :class="positiveOrNegative(relatorio.liquido)">{{relatorio.liquido | formatCoin}}</div>
                                </div><!-- /row -->
                            </div><!-- /card-body -->
                        </div><!-- /card -->                        
                    </div><!-- /managers -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import {jsPDF} from 'jspdf'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import html2canvas from 'html2canvas'
// import * as rasterizeHTML from 'rasterizehtml'
import 'vue-loading-overlay/dist/vue-loading.css'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            error: '',
            users: [],
            user: 'all',
            searchs: '',
            date1: today,
            date2: today,
            managers: [],
            salesman: {},
            managersIds: {},
            relatorio: {
                liquido: 0,
                retiradas: 0,
                lancamentos: 0,
                num_apostas: 0,
                num_premios: 0,
                total_saldo: 0,
                total_premios: 0,
                total_apuracao: 0,
                total_comissao: 0,
                total_comissao_gerentes: 0
            },
            loading: false,
            isLoading: false,
            nothing_found: false,
            include_commission: 1,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        search() {

            const self = this;
            const url = self.user == 'all' ? `dashboard/cash/manager/${self.date1}/between/${self.date2}/all` : `dashboard/cash/manager/${self.date1}/between/${self.date2}/user/${self.user}`;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                
                self.searchs = '';
                self.managers = [];
                self.isLoading = true;
                self.relatorio.liquido = 0;
                self.relatorio.retiradas = 0;
                self.relatorio.lancamentos = 0;
                self.relatorio.num_apostas = 0;
                self.relatorio.num_premios = 0;
                self.relatorio.total_saldo = 0;
                self.relatorio.total_premios = 0;
                self.relatorio.total_apuracao = 0;
                self.relatorio.total_comissao = 0;
                self.relatorio.total_comissao_gerentes = 0;

                api.get(url).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                            self.salesman = response.data.salesman;
                            self.managers = Object.keys(response.data.managers).map((key) => {
                                return response.data.managers[key];
                            }).filter((item) => item.total_apuracao > 0);
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            )
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            )
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        determinedCommission(id) {
            try {
                return this.users.find((item) => item.id == id).permissoes.comissao_do_apurado;
            } catch(e) {
                return 'nao';
            }
        }, 
        positiveOrNegative(value) {
            return value < 0 ? 'text-danger' : value == 0 ? 'text-black' : 'text-success';
        },
        calcCommission(total_apuracao, total_premios, total_comissao, total_comissao_ao_vivo, comissao, comissao_do_apurado) {

           let valor = 0;
           let subtrai = total_apuracao - total_premios - total_comissao - total_comissao_ao_vivo;

           if (comissao_do_apurado == 'sim') valor = (total_apuracao / 100) * comissao;
           if (subtrai > 0 && comissao_do_apurado == 'nao') valor = (subtrai / 100) * comissao;

           return this.include_commission == 1 ? valor : 0;
        },
        searchUser(event) {
            this.searchs = event.target.value;
        },
        printReport(item) {
            
            const self = this;
            const print_iframe = document.createElement('iframe');

            self.isLoading = true;

            try {
                setTimeout(() => {
                    html2canvas(document.querySelector(`.capture-${item.id}`)).then((canvas) => {

                        print_iframe.width = '0px';
                        print_iframe.height = '0px';
                        print_iframe.src = 'about:blank';
                        print_iframe.style.visibility = 'hidden';

                        print_iframe.onload = function() {
                            let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                                print_script_tag.type = "text/javascript";
                
                            let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                                print_script_tag.appendChild(print_script);

                            print_iframe.contentWindow.document.body.appendChild(canvas);
                            print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                            print_iframe.contentWindow.Print();
                        };
                            
                        document.body.appendChild(print_iframe);
                        print_iframe.remove();
                        self.isLoading = false;
                    });
                }, 1000);
            } catch(e) {

                self.isLoading = false;

                Swal.fire(
                    'Falha',
                    'Não foi possível imprimir o relatório!',
                    'error'
                );
            }
        },
        printReportOrderByManager(type, action) {

            let self = this;

            if (Object.keys(self.salesman).length == 0) {
                Swal.fire(
                    'Falha',
                    'Nenhum relatório disponível!',
                    'error'
                );
            } 
            else 
            {
                let html = ``;
                let count = 1;
                let anteior_value = 'gerente';
                let group = self.groupBy(self.salesman);
                let print_iframe = document.createElement('iframe');
                let date1 = moment(self.date1).format('DD/MM/YYYY');
                let date2 = moment(self.date2).format('DD/MM/YYYY');

                html += `
                    <h1 style="margin-top: 1em;text-align: center;">
                        <strong>${document.title.toUpperCase()}</strong>
                    </h1>
                    <p style="margin-top: 10px;text-align: center;">
                        <u>${type == 1 ? `Lista de positivos do dia ${date1} até ${date2}` : `Lista de negativos do dia ${date1} até ${date2}`}</u>
                    </p>
                `;

                for (let salesman of group) {

                    let balance = self.checkBalance(salesman.apurou, salesman.premios, salesman.comissao, salesman.comissao_ao_vivo, salesman.retiradas, salesman.lancamentos);

                    if (salesman.g_id) {
                        
                        if (type == 1 && balance > 0) {

                            if (salesman.g_id != anteior_value) {

                                count = 1;
                                html += `<p style="margin: 1em 0;">
                                    <strong>
                                        ${salesman.gerente} - ${salesman.gerente_endereco}
                                    </strong>
                                </p>`;
                            }

                            html += `<p style="margin-left: 2em;">
                                ${count++}. ${salesman.vendedor}: <strong>R$ ${self.formatCoin(balance)}</strong>
                            </p>`;

                            anteior_value = salesman.g_id;

                        } else if (type == 2 && balance < 0) {

                            if (salesman.g_id != anteior_value) {

                                count = 1;

                                html += `<p style="margin: 1em 0;">
                                    <strong>
                                        ${salesman.gerente} - ${salesman.gerente_endereco}
                                    </strong>
                                </p>`;
                            }

                            html += `<p style="margin-left: 2em;">
                                ${count++}. ${salesman.vendedor}: <strong>R$ ${self.formatCoin(balance)}</strong>
                            </p>`;

                            anteior_value = salesman.g_id;
                        }
                    }
                }

                if (anteior_value == 'gerente') {
                    Swal.fire(
                        'Falha',
                        'Nenhum relatório disponível para a opção escolhida!',
                        'error'
                    );
                } else {
                    print_iframe.width = '0px';
                    print_iframe.height = '0px';
                    print_iframe.src = 'about:blank';
                    print_iframe.style.visibility = 'hidden';

                    print_iframe.onload = function() {

                        print_iframe.contentWindow.document.body.innerHTML = `<html>
                            <head>
                                <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table{margin-bottom:10px}.table td {padding:10px}</style>
                                </head>
                            <body class="content">
                                ${html}
                            </body>
                        </html>`;

                        if (action == 'print') {
                            let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                            print_script_tag.type = "text/javascript";
                
                            let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                            print_script_tag.appendChild(print_script);

                            print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                            print_iframe.contentWindow.Print();
                        } else {

                            self.isLoading = true;

                            setTimeout(() => {

                                let doc = new jsPDF('p','pt','a4');
                                let pWidth = doc.internal.pageSize.width;
                                let margin = 18; // narrow margin - 1.27 cm (36);
                                let scale = (pWidth - margin * 2) / pWidth;

                                doc.html(html, {
                                    callback: function (doc) {
                                        self.isLoading = false;
                                        // doc.output("dataurlnewwindow");
                                        doc.save(`${Math.floor(Math.random() * 1000000)}.pdf`);
                                    },
                                    x: margin,
                                    y: margin,
                                    width: pWidth,
                                    windowWidth: pWidth,
                                    html2canvas: {
                                        scale: scale,
                                        width: pWidth
                                    },
                                });
                            }, 100);   
                        }
                    };
                        
                    document.body.appendChild(print_iframe);
                    print_iframe.remove();
                }
            }
        },
        async selectDialog(value) {

            const { value: formValues } = await Swal.fire({
                title: 'Relatório',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'OK',
                html: `
                    <select id="swal-input1" class="swal2-input w-100">
                        <option value="1">Imprimir</option>
                        <option value="2">Salvar PDF</option>
                    </select>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        tipo: document.getElementById('swal-input1').value,
                    }
                },
            });

            if (formValues) {
                
                if (formValues.tipo == 1) {
                    this.printReportOrderByManager(value, 'print');
                } else {
                    this.printReportOrderByManager(value, 'pdf');
                }
            }
        },
        checkBalance(total_apuracao, total_premios, total_comissao, total_comissao_ao_vivo, retiradas, lancamentos) {
            return parseFloat(((parseFloat(total_apuracao) - parseFloat(total_premios) - parseFloat(total_comissao) - parseFloat(total_comissao_ao_vivo)) + parseFloat(lancamentos)) - parseFloat(retiradas));
        },
        groupBy(objectArray) {

            let arr = [];
            let group = {};

            for (let k in objectArray) {

                let salesman = objectArray[k];

                if (salesman.g_nome) {

                    if (group[salesman.g_id]) {
                    
                        group[salesman.g_id].push({
                            g_id: salesman.g_id,
                            apurou: salesman.apurou,
                            premios: salesman.premios,
                            retiradas: salesman.retiradas,
                            lancamentos: salesman.lancamentos,
                            comissao: salesman.comissao,
                            comissao_ao_vivo: salesman.comissao_ao_vivo,
                            vendedor: salesman.nome,
                            gerente: salesman.gerente.nome,
                            gerente_endereco: salesman.gerente.endereco
                        });
                    } else {
                        group[salesman.g_id] = [{
                            g_id: salesman.g_id,
                            apurou: salesman.apurou,
                            premios: salesman.premios,
                            retiradas: salesman.retiradas,
                            lancamentos: salesman.lancamentos,
                            comissao: salesman.comissao,
                            comissao_ao_vivo: salesman.comissao_ao_vivo,
                            vendedor: salesman.nome,
                            gerente: salesman.gerente.nome,
                            gerente_endereco: salesman.gerente.endereco
                        }];
                    }
                }
            }

            var order = Object.keys(group).map(key => {
                return group[key];
            });

            for (let k in order) {
                
                for (let j in order[k]) {
                    arr.push(order[k][j]);
                }
            }

            return arr.sort((a, b) => this.removeAccents(a.gerente).localeCompare(this.removeAccents(b.gerente)));
        },
        savePDF(item) {

            const self = this;
            const pdf = new jsPDF({
                orientation: "portrait", // landscape or portrait
                unit: "mm",
                format: "a4",
            });

            self.isLoading = true;

            try {
                setTimeout(() => {
                    
                    html2canvas(document.querySelector(`.capture-${item.id}`)).then((canvas) => {

                        const imgData = canvas.toDataURL("image/jpeg");

                        const imgProps = pdf.getImageProperties(imgData);
                        const margin = 0.1;

                        const pdfWidth = pdf.internal.pageSize.width * (1 - margin);
                        const pdfHeight = pdf.internal.pageSize.height * (1 - margin);

                        const x = pdf.internal.pageSize.width * (margin / 2);
                        const y = pdf.internal.pageSize.height * (margin / 2);

                        const widthRatio = pdfWidth / imgProps.width;
                        const heightRatio = pdfHeight / imgProps.height;
                        const ratio = Math.min(widthRatio, heightRatio);
                        
                        const w = imgProps.width * ratio;
                        const h = imgProps.height * ratio;
                        pdf.addImage(imgData, "JPEG", x, y, w, h);
                        // pdf.output('dataurlnewwindow');
                        pdf.save(`${self.removeAccents(item.nome)} - ${self.date1} - ${self.date2}.pdf`);
                        self.isLoading = false;
                    });
                }, 1000);
            } catch(e) {

                self.isLoading = false;

                Swal.fire(
                    'Falha',
                    'Não foi possível gerar o PDF do relatório!',
                    'error'
                );
            }
        },
        saveImage(item) {

            const self = this;
            const link = document.createElement('a');

            self.isLoading = true;

            try {
                setTimeout(() => {
                    html2canvas(document.querySelector(`.capture-${item.id}`)).then((canvas) => {
                        self.isLoading = false;
                        link.download = `${self.removeAccents(item.nome)} - ${self.date1} - ${self.date2}.jpeg`;
                        link.href = canvas.toDataURL('image/jpeg');
                        link.click();
                    });
                }, 1000);
            } catch(e) {

                self.isLoading = false;

                Swal.fire(
                    'Falha',
                    'Não foi possível gerar a imagem do relatório!',
                    'error'
                );
            }
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    },
    watch: {
        managers(results) {

            if (results.length) {
                
                this.relatorio.liquido = 0;
                this.relatorio.retiradas = 0;
                this.relatorio.lancamentos = 0;
                this.relatorio.total_saldo = 0;
                this.relatorio.num_apostas = 0;
                this.relatorio.total_premios = 0;
                this.relatorio.total_apuracao = 0;
                this.relatorio.total_comissao = 0;
                this.relatorio.total_comissao = 0;

                for (const item of results) {
                    this.relatorio.retiradas += parseFloat(item.retiradas);
                    this.relatorio.lancamentos += parseFloat(item.lancamentos);
                    this.relatorio.num_apostas += parseInt(item.num_apostas);
                    this.relatorio.num_premios += parseInt(item.num_premios);
                    this.relatorio.total_premios += parseFloat(item.total_premios);
                    this.relatorio.total_apuracao += parseFloat(item.total_apuracao);
                    this.relatorio.total_comissao += parseFloat(item.total_comissao);
                    this.relatorio.total_comissao += parseFloat(item.total_comissao_ao_vivo);
                    this.relatorio.total_comissao_gerentes += parseFloat(this.calcCommission(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.comissao, this.determinedCommission(item.id)));
                }

                this.relatorio.total_comissao_gerentes = this.include_commission == 1 ? this.relatorio.total_comissao_gerentes : 0;
                this.relatorio.total_saldo = parseFloat(this.relatorio.total_apuracao - this.relatorio.total_premios - this.relatorio.total_comissao - this.relatorio.total_comissao_gerentes);
                this.relatorio.liquido = (parseFloat(this.relatorio.total_saldo) + parseFloat(this.relatorio.lancamentos)) - this.relatorio.retiradas;
            }
        }
    },
    computed: {
        filteredListManagers() {
            return this.managers.filter((item) => {
                return item.nome.toLowerCase().trim().includes(this.searchs.toLowerCase().trim()) || item.usuario.toLowerCase().trim().includes(this.searchs.toLowerCase().trim())
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;
        self.nothing_found = false;

        api.get('dashboard/users/2').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.users = response.data.users.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });
                break;
                case 'nothing_found':
                    self.nothing_found = true;
                break;
                default:
                    self.error = 'Ocorreu um erro, tente novamente!';
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>